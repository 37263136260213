export const pages = [
  { index: 0, name: 'overview.md', route: '/overview', visible: true },
  { index: 1, name: 'skills.md', route: '/skills', visible: true },
  { index: 2, name: 'experience.md', route: '/experience', visible: true },
  { index: 3, name: 'education.md', route: '/education', visible: true },
  { index: 4, name: 'projects.md', route: '/projects', visible: true },
  { index: 5, name: 'certificates.md', route: '/certificates', visible: true },
  { index: 6, name: 'accomplishments.md', route: '/accomplishments', visible: true },  
  { index: 7, name: 'docs.md', route: '/docs', visible: false },  
];
